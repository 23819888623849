import React from 'react';
import Checkbox from '@jetshop/ui/Checkbox/Checkbox';
import { Field, ErrorMessage } from 'formik';
import { Wrapper, ErrorSpan } from '../Forms/Input';
import { cx } from 'linaria';

export const CheckBoxWithLabel = ({ name, item, disabled }) => {
  const { id, value, label } = item;
  return (
    <Wrapper className={cx(disabled && 'disabled')}>
      <Field
        name={name}
        render={({ field, form: { setFieldValue } }) => (
          <Checkbox
            className="label-checkbox"
            type="checkbox"
            key={id || `${name}${value}`}
            id={id || `${name}${value}`}
            label={label}
            disabled={disabled}
            {...field}
            initialValue={value}
            checked={field.value}
            onChange={() => setFieldValue(field.name, !field.value)}
          />
        )}
      />
      <ErrorMessage name={name} component={ErrorSpan} />
    </Wrapper>
  );
};
