import t from '@jetshop/intl';
import React, { useState, useContext } from 'react';
import { GlobalError } from '../Forms/GlobalError';
import customerProfileQuery from './customerProfileQuery.gql';
import { BlockTitle, ButtonContainer, PrimaryButton } from './ProfilePage';
import { Success } from '../Forms/Success';
import { useMutation } from '@apollo/react-hooks';
import UpdateCustomerMutation from '@jetshop/core/data/mutations/updateCustomer.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { getCustomerUpdateInput } from '@jetshop/core/components/Auth/useCustomer';
import ParseJson from '../Util/ParseJson';
import { styled } from 'linaria/react';
import SiteContentContext from '../SiteContent/SiteContentContext';

const Wrapper = styled.div``;

const InterestListWrapper = styled.ul`
  columns: 5;
`;

const Interest = styled.li`
  margin: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    position: relative;
    top: -1px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`;

export const InterestList = ({ interestList, setInterestList }) => {
  function updateInterestList(name) {
    const newList = interestList.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          checked: !item.checked,
        };
      }
      return item;
    });
    setInterestList(newList);
  }

  return (
    <InterestListWrapper>
      {interestList &&
        interestList.map((item, index) => {
          return (
            <InterestCheckbox
              key={index}
              item={item}
              updateInterestList={updateInterestList}
            />
          );
        })}
    </InterestListWrapper>
  );
};

const InterestCheckbox = ({ item, updateInterestList }) => {
  return (
    <Interest
      className={item.checked ? 'checked' : ''}
      onClick={() => updateInterestList(item.name)}
    >
      <input type="checkbox" checked={item.checked} />
      {item.name}
    </Interest>
  );
};

const Interests = ({ customer }) => {
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(false);
  const { selectedChannel } = useContext(ChannelContext);

  //ALL INTERESTS
  const { interests: interestsData } = useContext(SiteContentContext);
  const interestsDataList = interestsData.split('\n');

  //INTERESTS FROM PROFILE
  const myInterestsData = customer?.externalAttributes?.filter(
    (item) => item.name === 'favoriteInterests'
  )[0]?.value;
  const myInterests = myInterestsData ? ParseJson(myInterestsData) : false;

  //STATE OF INTERESTS
  const [interestList, setInterestList] = useState(
    interestsDataList.map((name) => {
      return {
        name,
        checked: myInterests.content.includes(name),
      };
    })
  );

  const [updateInterests] = useMutation(UpdateCustomerMutation, {
    refetchQueries: [{ query: customerProfileQuery }],
  });

  if (!interestsDataList || interestsDataList.length === 0) {
    return null;
  }

  function submit() {
    setIsSubmitting(true);

    //FILTER ON CHECKED VALUES
    const list = interestList
      .filter((item) => item.checked)
      .map((interest) => interest.name);

    const extAttr = [
      {
        name: 'favoriteInterests',
        value: JSON.stringify(list),
      },
    ];

    updateInterests({
      variables: {
        input: {
          ...getCustomerUpdateInput({
            customer,
            billingAddressFields: customer.billingAddressFields,
            culture: selectedChannel.language.culture,
            countryCode: selectedChannel.country.code,
          }),
          externalAttributes: extAttr,
        },
      },
    })
      .then(() => {
        setSuccess(true);
        setIsSubmitting(false);
        setStatus(t('Your preferences have been updated.'));
      })
      .catch(() => {
        setIsSubmitting(false);
        setStatus(t('Something went wrong saving information. Try again?'));
      });
  }

  return (
    <div className={`block`} id="interests">
      <BlockTitle>{t('Interests')}</BlockTitle>
      <Wrapper>
        <div onClick={() => setStatus(false)}>
          <InterestList
            interestList={interestList}
            setInterestList={setInterestList}
          />
        </div>
        {status && !success && (
          <GlobalError css={{ marginBottom: '2em' }}>{status}</GlobalError>
        )}
        {status && success && (
          <Success css={{ marginBottom: '2em' }}>{status}</Success>
        )}
        <ButtonContainer>
          <PrimaryButton
            style={{ marginTop: '0.5rem', maxWidth: '50%' }}
            disabled={isSubmitting}
            onClick={() => submit()}
          >
            {t(isSubmitting ? 'Hold on a moment…' : 'Save')}
          </PrimaryButton>
        </ButtonContainer>
      </Wrapper>
    </div>
  );
};

export default Interests;
