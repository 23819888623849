import t from '@jetshop/intl';
import React, { useState, useContext } from 'react';
import { GlobalError } from '../Forms/GlobalError';
import { CheckBoxWithLabel } from '../ui/CheckBoxWithLabel.js';
import customerProfileQuery from './customerProfileQuery.gql';
import { BlockTitle, ButtonContainer, PrimaryButton } from './ProfilePage';
import { Success } from '../Forms/Success';
import LoginMutation from '../Auth/LoginMutation.gql';
import { useLoginStatus } from '@jetshop/flight-voyado';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import UpdateCustomerMutation from '@jetshop/core/data/mutations/updateCustomer.gql';

import ChannelContext from '@jetshop/core/components/ChannelContext';
import { getCustomerUpdateInput } from '@jetshop/core/components/Auth/useCustomer';
import SiteContentContext from '../SiteContent/SiteContentContext';

const translations = {
  acceptsEmail: t('I want newsletters'),
  acceptsPostal: t('I want mail'),
  acceptsSms: t('I want SMS'),
};

export function Communication({ customer }) {
  const [success, setSuccess] = useState(false);
  const { selectedChannel } = useContext(ChannelContext);

  const { communication: communicationText } = useContext(SiteContentContext);

  const { isSoftLoggedIn } = useLoginStatus({
    loginMutation: LoginMutation,
  });

  const [updateCommunication] = useMutation(UpdateCustomerMutation, {
    refetchQueries: [{ query: customerProfileQuery }],
  });

  const communication =
    Object.entries(customer?.communication).filter(
      ([type]) => type !== '__typename'
    ) ?? [];

  return (
    <div className={`block`} id="newsletter">
      <BlockTitle>
        {communicationText ? communicationText : t('Advertising')}
      </BlockTitle>
      <Formik
        initialValues={{
          ...Object.fromEntries(communication),
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          updateCommunication({
            variables: {
              input: {
                ...getCustomerUpdateInput({
                  customer,
                  billingAddressFields: customer.billingAddressFields,
                  culture: selectedChannel.language.culture,
                  countryCode: selectedChannel.country.code,
                }),
                preferences: {
                  type: {
                    ...values,
                    culture: selectedChannel.language.culture,
                  },
                },
              },
            },
          })
            .then(() => {
              setSuccess(true);
              actions.setSubmitting(false);
            })
            .catch(() => {
              actions.setSubmitting(false);
              actions.setStatus({
                globalError: t(
                  'Something went wrong saving information. Try again?'
                ),
              });
            });
        }}
      >
        {({ isSubmitting, status }) => {
          return (
            <Form>
              <>
                {communication.map(([type, value]) => {
                  return (
                    <CheckBoxWithLabel
                      key={type}
                      value={value}
                      name={type}
                      disabled={isSoftLoggedIn}
                      item={{
                        label: translations[type],
                        value,
                      }}
                    />
                  );
                })}
                {status && status.globalError && (
                  <GlobalError css={{ marginBottom: '2em' }}>
                    {status.globalError}
                  </GlobalError>
                )}

                {success && (
                  <Success css={{ marginBottom: '2em' }}>
                    {t('Your preferences have been updated.')}
                  </Success>
                )}

                <ButtonContainer>
                  <PrimaryButton
                    style={{ marginTop: '0.5rem', maxWidth: '50%' }}
                    type="submit"
                    disabled={isSubmitting || isSoftLoggedIn}
                  >
                    {t(isSubmitting ? 'Hold on a moment…' : 'Save')}
                  </PrimaryButton>
                </ButtonContainer>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
