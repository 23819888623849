import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { useLocation } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import { smallCaps } from '../../ui/Headings';
import Address from '../Signup/Address';
import LoginFields from '../Signup/LoginFields';
import { theme } from '../../Theme';
import { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { useState } from 'react';
import { InterestList } from '../../MyPages/Interests';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  button {
    width: 100%;
    max-width: 100%;
  }
  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }
`;
const Section = styled.section`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 80rem;
  margin-bottom: 3rem;
  > header {
    width: 100%;
    flex-grow: 1;
  }
  > div {
    width: 48%;
  }
  ${theme.below.md} {
    display: block;
    width: 80%;
    > div {
      width: 100%;
    }
  }
  ${theme.below.sm} {
    width: 100%;
  }
`;

const InterestsWrapper = styled.div`
  margin: 1rem 0 2rem;
  > ul {
    columns: 2;
    ${theme.below.sm} {
      columns: 1;
    }
  }
`;

function HeaderWithStatus({ status }) {
  if (!status) {
    return (
      <>
        <p>
          {t(
            'Welcome to our online store! We need to create a profile before you continue.'
          )}
        </p>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }

  if (status.isAdditionalDataRequired) {
    return (
      <>
        <p>{t("We're missing some key information.")}</p>
        <p>{t('Please fill in required fields to continue.')}</p>
      </>
    );
  }

  if (status.isNonExistingCustomer) {
    return (
      <>
        <p>
          {t('No customer found with entered { lookupKey }.', {
            lookupKey: 'e-mail',
          })}
        </p>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }
  return null;
}

function formatSignupInput(values, interestList) {
  //FILTER ON CHECKED VALUES
  const list =
    interestList &&
    interestList
      .filter((item) => item.checked)
      .map((interest) => interest.name);

  const externalAttributes = [
    {
      name: 'favoriteInterests',
      value: JSON.stringify(list),
    },
  ];

  return {
    ...values,
    externalAttributes,
  };
}

function VoyadoSignup() {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const { state } = useLocation();

  //ALL INTERESTS
  const { interests: interestsData } = useContext(SiteContentContext);
  const interestsDataList = interestsData && interestsData.split('\n');

  const [interestList, setInterestList] = useState(
    interestsDataList &&
      interestsDataList.map((name) => {
        return {
          name,
          checked: false,
        };
      })
  );

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  return (
    <SignupFormProvider
      LoadingComponent={<LoadingPage />}
      lookupData={state?.customer}
      additionalFormatter={(values) => formatSignupInput(values, interestList)}
    >
      <MaxWidth style={{ marginTop: '2em' }}>
        <Section>
          <Intl>{(t) => <Head data={{ title: t('Signup') }} />}</Intl>
          <header>
            <h1
              className={cx('heading', smallCaps)}
              data-testid="signup-page-header"
            >
              {t('Sign up')}
            </h1>
            <HeaderWithStatus status={state?.status} />
          </header>
          <div>
            <Address />
            <InterestsWrapper>
              <h4>Intressen</h4>
              <InterestList {...{ interestList, setInterestList }} />
            </InterestsWrapper>
          </div>
          <div>
            <LoginFields />
          </div>
        </Section>
      </MaxWidth>
    </SignupFormProvider>
  );
}
export default VoyadoSignup;
