import React, { useContext } from 'react';
import { Formik } from 'formik';
import TrendButton from '../../ui/Button';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { CustomerStatusHeaderText } from './VoyadoLookup';
import Input from '../../Forms/Input';
import SiteContentContext from '../../SiteContent/SiteContentContext';

export const LookupFieldContainer = styled.section`
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  dl {
    display: flex;
    flex-flow: row wrap;
  }
  dt {
    flex-basis: 50%;
    padding: 2px 4px;
    text-align: right;
    font-weight: 600;
    ::after {
      content: ':';
    }
  }
  dd {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
`;

export const ExternalLookupField = ({ lookup, isExternalLookupPending }) => {
  const { loginText } = useContext(SiteContentContext);
  return (
    <>
      <CustomerStatusHeaderText>{loginText}</CustomerStatusHeaderText>
      <Formik
        initialValues={{ lookupKey: '' }}
        onSubmit={(values) => lookup(values.lookupKey)}
      >
        {({ handleChange, handleBlur, handleSubmit }) => {
          return (
            <LookupFieldContainer>
              <form onSubmit={handleSubmit}>
                <Input
                  label={t('Epost')}
                  name="lookupKey"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TrendButton
                  data-testid="commit-lookup"
                  type="submit"
                  disabled={isExternalLookupPending}
                >
                  {t('Login')}
                </TrendButton>
              </form>
            </LookupFieldContainer>
          );
        }}
      </Formik>
    </>
  );
};
