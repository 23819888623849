import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import t from '@jetshop/intl';
import React from 'react';
import Input from '../../Forms/Input';
import { PID } from './PID';
import { SmallCaps } from '../../ui/Headings';

export default function Address(props) {
  const { fields } = useAddressFields();

  return (
    <section>
      <SmallCaps className="heading">{t('Contact Details')}</SmallCaps>

      <p>
        {t(
          'Please fill in your contact details for shipping and communication.'
        )}
      </p>

      <PID />

      {fields.map((field) => {
        // Map over billingAddressFields and display them
        return <Input {...field.inputProps} />;
      })}
    </section>
  );
}
